.main_root {
  overflow: hidden;
}

.route__container {
  flex: 1;
  // overflow-y: auto;
  // height: 90vh;
  overflow: auto;
}

.subRoot {
  display: flex;
}

body {
  overflow-y: hidden; /* Hide vertical scrollbar */
  overflow-x: hidden; /* Hide horizontal scrollbar */
}
