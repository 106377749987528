.name {
  color: var(--lighscd);
}
.image {
  width: 250px;
}
.color1 {
  color: brown;
  font-size: x-large;
  font-style: bold;
}
.image_size {
  width: 150px;
}
