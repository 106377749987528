.add-new-link-btn {
  color: var(--color-btn-text);
  background-color: var(--color-btn-bg);
  box-shadow: var(--color-btn-shadow), var(--color-btn-inset-shadow);
  transition: 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  transition-property: color, background-color, border-color;
  border-radius: 5px;
  padding: 5px 10px;
  border: 2px solid var(--color-btn-border);
  cursor: pointer;
  margin: 10px;
  font-size: 15px;
  font-weight: 600;
}
.add-new-link-alert {
  cursor: pointer;
  width: 50%;

  /*
  transition: 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  transition-property: color, background-color, border-color;
  color: var(--color-btn-text);
  background-color: var(--color-btn-bg);
  margin: 10px;
  font-size: 15px;
  font-weight: 600;*/
}

.add-new-link-btn-red {
  color: var(--light-red) !important;
  border: 2px solid var(--light-red);
}

.add-new-link-btn:hover {
  text-decoration: none;
  color: var(--color-btn-text);
  background-color: white;
}

.ft-size-15 {
  font-size: 15px;
}
.ft-size-16 {
  font-size: 16px;
}
.ft-size-18 {
  font-size: 18px;
}
.ft-size-20 {
  font-size: 20px;
}

.float-content-set-right {
  float: right;
}

.table_outer_div {
  box-shadow: 1px 1px 6px var(--light-yellow-1);
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid transparent;
  border-radius: 0px;
  margin: 5px 0;
}
.table_outer_div_blue {
  box-shadow: 1px 1px 6px var(---light-red);
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid transparent;
  border-radius: 0px;
  margin: 5px 0;
  width: 500px;
}
.table_outer_div_dis {
  box-shadow: 1px 1px 6px var(---light-red);
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid transparent;
  border-radius: 0px;
  margin: 5px 0;
}

.table_outer_div-border-none {
  box-shadow: none !important;
}

.table_outer_div tbody tr {
  border-bottom: 1px solid var(--light-yellow-1);
  color: var(--light-black);
  letter-spacing: 0.4px;
}

.table_outer_div tbody tr:last-child {
  border: 1px solid #ffffff00;
}

.table_outer_div thead tr {
  border: 1px solid var(--light-yellow-1);
  background-color: var(--light-yellow-1);
  color: var(--mid-brown);
  letter-spacing: 0.4px;
  text-transform: uppercase;
}

.table_outer_div .header-txt {
  padding: 10px 10px 0 10px;
  color: var(--mid-brown);
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.table_outer_div .header-txt-camelcase {
  padding: 10px 10px 0 10px;
  color: var(--mid-brown);
  letter-spacing: 0.4px;
}
.table {
  margin-bottom: 0 !important;
}

.table .view-td {
  padding: 0.75rem 0;
}

.machine-state-off {
  color: red;
}

.machine-state-on {
  color: #43d865;
}

.title-tag-style {
  padding: 0;
  font-weight: 500;
  line-height: 44px;
  display: block;
}

.normal-p-tag-style {
  margin: 0;
  padding: 0;
  line-height: 20px;
}

.font-color-white {
  color: #fff;
}

.font-color-red {
  color: red;
}

.text-align-center {
  text-align: center;
}

.add-new-link-btn-thin-border {
  color: var(--mid-orange);
  background-color: var(--color-btn-bg);
  box-shadow: var(--color-btn-shadow), var(--color-btn-inset-shadow);
  transition: 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  transition-property: color, background-color, border-color;
  border-radius: 1px;
  /* padding: 4px 10px; */
  /* border: 1px solid var(--color-btn-border); */
  cursor: pointer;
  margin: 0 0 10px 0;
  font-size: 15px;
  font-weight: 400;
}

.add-new-link-btn-thin-border:hover {
  text-decoration: none;
  color: #3f51b5;
  background-color: white;
}

.margin-top-none {
  margin-top: 0px !important;
}

.a-tag-disable {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  color: var(--light-mid-gray);
  border-color: var(--light-mid-gray);
}

.background-color-set-red {
  color: var(--light-red) !important;
  background-color: var(--color-btn-bg) !important;
}

.background-color-set-blue {
  color: var(--mid-brown) !important;
  background-color: var(--color-btn-bg) !important;
}

.title-h5-style {
  padding: 10px 10px 0 10px;
  color: var(--mid-brown);
  letter-spacing: 0.4px;
  text-transform: uppercase;
}

.main-container-div {
  overflow-y: auto;
  height: 90vh;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.form-outer-div-search {
  margin-top: 2px;
}

.form-outer-div-search svg {
  color: var(--mid-brown);
}

.header-title-style {
  color: var(--mid-brown);
  letter-spacing: 0.4px;
}

.country-image-mou {
  width: 30px;
}
