.container-width-set-none {
  max-width: inherit !important;
}

.button-for-webpage:link,
.button-for-webpage:visited {
  background-color: var(--dark-blue);
  color: rgb(255, 255, 255);
  border: 2px solid var(--dark-blue);
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

.button-for-webpage:hover,
.button-for-webpage:active {
  background-color: var(--light-blue);
  color: white;
}

.margin-top-set-3vh {
  margin-top: 3vh;
}
.margin-top-set-5vh {
  margin-top: 5vh;
}

.margin-top-set-20vh {
  margin-top: 20vh;
}

.margin-top-set-10vh {
  margin-top: 10vh;
}

.login {
  height: 100vh !important;
}

.logo-login {
  width: 150px;
  border-radius: 10px;
}

.font_head {
  font-family: 'Times New Roman', Times, serif;
  text-align: center;
  color: var(--mid-brown);
}
.font_normal {
  text-align: center;
}

.margin_top {
  margin-top: 100px;
}

.back_image {
  background-image: url('https://arts.pdn.ac.lk/siteimages/slider/slide2.jpg');
  background-color: #cccccc; /* Used if the image is unavailable */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}
