.text-color {
  color: var(--mid-brown);
}
.total-width {
  width: 1000px;
}
.text-color-red {
  color: var(--light-red);
}

.alert-button-coordinator {
  margin-bottom: 0px !important;
  width: 300px;
  display: inline;
  margin-right: 10px;
}

.coordinator-list-mou a {
  text-decoration: none;
}
