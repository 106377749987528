.card-mou {
  position: relative;
  display: block;
  margin-bottom: 0.75rem;
  background-color: #fff;
  border-radius: 0.25rem;
  box-shadow: 1px 1px 6px var(--light-yellow-1);
  border: 0 solid transparent;
}

.side-box {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  width: 60px;
  font-size: 26px;
  border-radius: 0 0 0 100px;
  text-align: center;
}

.side-box i {
  position: relative;
  top: 10px;
  left: 5px;
}

.text-delayed-color {
  color: #dc3545;
}

.text-ongoing-color {
  color: #17a2b8;
}

.text-completed-color {
  color: #28a745;
}

.text-users-color {
  color: #ffc107;
}

.home-top-dashboard a {
  text-decoration: none;
}

.card-mou:hover {
  background-color: var(--light-yellow-1);
}
