.toggle-switch-text {
  height: 27px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21.86px;
  display: flex;
  align-items: flex-start;
  color: #9396a6;
  width: 44px;
}

.toggle-switch-checkbox {
  height: 100;
  width: 0;
  visibility: hidden;
  margin-left: 30px;
  align-self: flex-end;
}

.toggle-switch-label {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 85px;
  height: 26px;
  background: transparent;
  border-radius: 100px;
  border: 1px solid rgba(150, 150, 150, 0.5);
  position: relative;
  transition: background-color 0.2s;
}

.toggle-switch-label .toggle-switch-button {
  content: '';
  position: absolute;
  left: -1px;
  top: -1px;
  width: 26px;
  height: 26px;
  border-radius: 45px;
  background: #c8cbd1;
  transition: 0.2s;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.dual-toggle-switch {
  display: flex;
}

.dual-toggle-switch-text {
  font-style: normal;
  font-size: 13px !important;
  line-height: 18px !important;
  align-items: center;
  color: #9396a6;
  margin-left: 10px;
}

.dual-toggle-switch-checkbox {
  height: 100;
  width: 0;
  visibility: hidden;
  align-self: flex-end;
  margin-left: 10px;
  margin-right: 10px;
}

.dual-toggle-switch-checkbox:checked
  + .dual-toggle-switch-label
  .dual-toggle-switch-button {
  left: calc(100%);
  transform: translateX(-100%);
  background: #ffffff;
}

.dual-toggle-switch-checkbox:checked + .dual-toggle-switch-text {
  left: calc(100%);
  transform: translateX(-100%);
  background: #ffffff;
}

.dual-toggle-switch-label {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 51px;
  height: 24px;
  background: #3f55c8;
  border-radius: 100px;
  border: 1px solid #ffffff;
  position: relative;
  transition: background-color 0.2s;
}

.dual-toggle-switch-label .dual-toggle-switch-button {
  content: '';
  position: absolute;
  left: -1px;
  top: -1px;
  width: 23px;
  height: 23px;
  border-radius: 45px;
  background: #ffffff;
  transition: 0.2s;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.dual-toggle-switch-label:active .dual-toggle-switch-button {
  width: 30px;
}
