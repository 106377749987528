.modal-content {
  border-radius: 0px !important;
  border: 1px solid var(--mid-brown);
}

.modal-content .modal-header {
  border-bottom: 1px solid var(--mid-brown);
  border-top: 1px solid var(--mid-brown);
  background-color: var(--light-yellow-1);
  border-radius: 0px !important;
}

.modal-content .modal-header .modal-title {
  color: var(--mid-brown);
  letter-spacing: 0.4px;
  text-transform: uppercase;
  font-size: 18px;
}
