.box_font {
  font-size: larger;
  font-family: serif;
}

.dashboard-mou {
  background-color: #fff;
  color: #777;
  padding: 15px;
}

.dashboard-mou span {
  font-weight: bold;
}
