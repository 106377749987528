.sidebar {
  height: 100vh;
  width: 0px;
  box-sizing: border-box;
  /* box-shadow: 0 0 2rem 0 rgb(0 0 0 / 5%); */
  overflow: hidden;
  transition: width 0.5s ease;
  background-color: var(--light-yellow);
}

.sidebar ul {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
}

.sidebar ul li {
  display: flex;
  align-items: center;
}

.sidebar ul li a {
  color: var(--mid-brown);
  text-decoration: none;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  white-space: nowrap;
}

.sidebar ul li a.active,
.sidebar ul li a:hover {
  background: white;
}

.sidebar ul li i {
  font-size: 18px;
  color: #111;
  font-weight: normal;
}

.active-sidebar-item {
  background: white;
}
