@import 'colors';
@import 'fonts';
@import 'buttons';
@import 'global.css';
@import 'responsive.css';

@import '~bootstrap/scss/bootstrap';

*:focus {
  outline: none !important;
}

.toast-container {
  position: absolute;
  top: 50px;
  right: 20px;
  z-index: 1000;
}
